import React from "react";
import { alpha, Box, Grid, Typography, useTheme } from "@mui/material";

import Benefit1Image from "../images/benefit_1.jpg";
import Benefit2Image from "../images/benefit_2.jpg";
import Benefit3Image from "../images/benefit_3.jpg";
import {
  HEADING_MOBILE_IMAGE_SX,
  PRIMARY_LIGHT_COLOR,
  SECTION_SX,
} from "../styles/shared";

import ServiceLogos from "./service-logos";
import Container from "../components/container";

function Benefit({ heading, reverse = false, src, subheading }) {
  return (
    <Grid
      container
      spacing={8}
      sx={{
        mb: { xs: 4, md: 8 },
        flexDirection: reverse ? "row-reverse" : "row",
      }}
    >
      <Grid item xs={12} md={6}>
        <BenefitImage
          src={src}
          reverse={reverse}
          sx={{
            ...(reverse
              ? {
                  borderTopLeftRadius: 175,
                  borderBottomLeftRadius: 175,
                  borderLeft: "10px solid",
                }
              : {
                  borderTopRightRadius: 175,
                  borderBottomRightRadius: 175,
                  borderRight: "10px solid",
                }),
            borderColor: PRIMARY_LIGHT_COLOR,
            display: { xs: "none", md: "block" },
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="h3" fontWeight="fontWeightBold" sx={{ mb: 2 }}>
          {heading}
        </Typography>
        <BenefitImage
          src={src}
          reverse={reverse}
          sx={{
            ...HEADING_MOBILE_IMAGE_SX,
          }}
        />
        <Typography fontSize="large" sx={{ maxWidth: {} }}>
          {subheading}
        </Typography>
      </Grid>
    </Grid>
  );
}

function BenefitImage({ reverse = false, src, sx = {} }) {
  const theme = useTheme();
  const overlayColor2 = alpha(theme.palette.primary.main, 0.1);
  const overlayColor1 = alpha(PRIMARY_LIGHT_COLOR, 0.1);

  return (
    <Box
      sx={{
        ...(reverse
          ? {
              backgroundImage: `linear-gradient(90deg, ${overlayColor2}, ${overlayColor1}), url(${src})`,
            }
          : {
              backgroundImage: `linear-gradient(90deg, ${overlayColor1}, ${overlayColor2}), url(${src})`,
            }),
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: 300,
        ...sx,
      }}
    />
  );
}

export default function Benefits() {
  return (
    <Box component="section" sx={{ ...SECTION_SX }}>
      <ServiceLogos />
      <Container sx={{ maxWidth: { md: "lg" } }}>
        <Benefit
          src={Benefit1Image}
          heading="Save time gathering status information"
          subheading={
            <>
              There are lots of status pages for different services and it's
              hard to know the overall status of everything. With StatusVista,
              you select only the services you care about and get a bird's-eye
              view of all of them in a single, convenient location.
            </>
          }
        />
        <Benefit
          reverse
          src={Benefit2Image}
          heading="Understand actual service uptime"
          subheading={
            <>
              Outages are incredibly costly for businesses and they can be
              difficult to track. StatusVista is designed to give you a quick
              overview of the current state of services as well as a view into
              how they perform over time.
            </>
          }
        />
        <Benefit
          src={Benefit3Image}
          heading="Be more proactive with your customers"
          subheading={
            <>
              Customers are often left in the dark when it comes to outages, not
              knowing why things aren't working as expected. By getting
              information quickly, you'll be able to stay on top of customer
              outreach from the time incidents occur to when they are resolved.
            </>
          }
        />
      </Container>
    </Box>
  );
}
