import React from "react";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";

import { ScrollProvider, useScrollContext } from "../contexts/scroll";
import DesktopImage from "../images/statusvista_desktop.png";
import MobileImage from "../images/statusvista_mobile.png";
import TabletImage from "../images/statusvista_tablet.png";

import Anchor from "../components/anchor";
import Benefits from "../components/benefits";
import Contact from "../components/contact";
import Container from "../components/container";
import Cta from "../components/cta";
import ExternalLink from "../components/external-link";
import Features from "../components/features";
import Footer from "../components/footer";
import Header from "../components/header";
import PageWrapper from "../components/page-wrapper";
import Stats from "../components/stats";
import SignupButton from "../components/signup-button";
import Testimonials from "../components/testimonials";

const pageDescription =
  "The ultimate status dashboard for developers. With the status of everything you depend on in one place, you can monitor services faster and more efficiently than ever before.";
const pageTitle = "One place to check the status of everything";

function Hero() {
  return (
    <Box
      component="section"
      sx={{
        bgcolor: "secondary.main",
        pt: { xs: 2, md: 15 },
        pb: { md: 20 },
      }}
    >
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6} lg={5} sx={{ mb: { md: 0 } }}>
            <Typography
              component="h1"
              variant="h2"
              fontWeight="fontWeightBold"
              sx={{ mb: 2 }}
            >
              A status dashboard for developers
            </Typography>
            <Typography fontSize="larger">
              With the status of everything in one place, you can monitor
              services faster and more efficiently than ever before.
            </Typography>
            <Stack direction="row" spacing={1} sx={{ mt: 4, mb: 2 }}>
              <SignupButton text="Sign up now" size="large" />
              <ExternalLink href={process.env.GATSBY_DEMO_CLIENT_URL}>
                <Button variant="outlined" size="large">
                  View demo
                </Button>
              </ExternalLink>
            </Stack>
            <Typography>
              <strong>StatusVista is free to try</strong> for as long as you'd
              like
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={7}>
            <Box
              sx={{
                display: { xs: "none", lg: "block" },
                position: "relative",
              }}
            >
              <img alt="desktop" src={DesktopImage} />
              <Box
                sx={{
                  position: "absolute",
                  width: "30%",
                  right: 0,
                  bottom: -70,
                }}
              >
                <img alt="mobile" src={MobileImage} />
              </Box>
            </Box>
            <Box
              sx={{
                display: { lg: "none" },
                position: "relative",
                height: { xs: 345, md: "auto" },
                overflow: { xs: "hidden", md: "visible" },
              }}
            >
              <Box sx={{ pr: 8 }}>
                <img alt="tablet" src={TabletImage} />
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  width: { xs: 225, md: "50%" },
                  right: 0,
                  bottom: { xs: -212, md: -70 },
                }}
              >
                <img alt="mobile" src={MobileImage} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

function Home() {
  const { headerHeight, pageContentRef } = useScrollContext();

  return (
    <>
      <Header />
      <Hero />
      <Anchor ref={pageContentRef} offset={headerHeight} />
      <Anchor id="benefits" offset={headerHeight} />
      <Benefits />
      <Anchor id="features" offset={headerHeight} />
      <Features />
      <Stats />
      <Anchor id="testimonials" offset={headerHeight} />
      <Testimonials />
      <Anchor id="contact" offset={headerHeight} />
      <Contact />
      <Cta />
      <Footer />
    </>
  );
}

export default function Page() {
  return (
    <PageWrapper pageDescription={pageDescription} pageTitle={pageTitle}>
      <ScrollProvider>
        <Home />
      </ScrollProvider>
    </PageWrapper>
  );
}
